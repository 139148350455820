*{outline:none !important;}
a{outline:0; color: rgba(36, 61, 145, 0.88); text-decoration: underline;}
.btn,.btn:focus,a:focus{outline:0}
a:hover {
  color: #F7C608;
}
body{background: #1B1B1B; text-indent: 0;font-family: 'Montserrat', sans-serif;font-size: 16px;color: #1D1D35;font-weight: 400;line-height: 28px; margin:0;scroll-behavior: smooth;}
body{-moz-osx-font-smoothing:grayscale;}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}
html {
  scroll-behavior: smooth;
}
img{max-width:100%;max-height:100%;width:auto;height:auto;}
.container {
  width: 1350px;
  max-width: 100%;
}

/*========header-main=========*/
.header-main {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
  padding: 30px 0 0;
}
.header-main .container {
  width: 1660px;
  max-width: 100%;
}
.header-main .navbar {
  padding: 0;
}
.header-main .navbar-brand {
  margin-right: 90px;
  padding: 0;
}
.header-main .navbar-nav .nav-item {
  margin-left: 50px;
}
.header-main .navbar-nav .nav-item:first-child {
  margin-left: 0px;
}
.header-main .navbar-nav .nav-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  text-transform: uppercase;
  color: #CCCCCC;
  text-decoration: none;
  padding: 0;
}
.header-main .navbar-nav .active .nav-link, .header-main .navbar-nav .nav-link:hover {
  color: #F7C608;
}
.get-in-touch {
  background: #6C7DCC;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  color: #fff;
  text-decoration: none;
  padding: 0;
  min-height: 55px;
  min-width: 262px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.get-in-touch:hover {
  color: #fff;
  box-shadow: 0px 15px 30px rgba(108, 125, 204, 0.2);
}
.get-in-touch img {
  margin-left: 10px;
  height: 20px;
}
.h-ic {
  background: #41413F;
  border-radius: 10px;
  width: 55px;
  height: 55px;
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.h-ic img{
  max-height: 28px;
  opacity: .5;
}
.h-ic:hover {
  box-shadow: 0px 15px 30px rgba(65, 65, 63, 0.3);
}
.h-ic:hover img {
  opacity: 1;
}
.home-top {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  background: url('image/home-top.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 980px;
}
.home-top .row {
  min-height: 980px;
  align-items: flex-end;
  justify-content: center;
}
.home-top-con {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.home-top-con h1 {
  font-weight: 800;
  font-size: 75px;
  line-height: 85px;
  text-align: center;
  color: #fff;
}
.home-top-con .button1 {
  margin: 50px 0 68px;
}
.button1 {
  background: #F7C608;
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: #201F1B;
  text-decoration: none;
  padding: 0;
  min-width: 248px;
  min-height: 75px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.button1:hover {
  /*background: #6C7DCC;*/
  text-decoration: none;
  color: #201F1B;
  box-shadow: 0px 12px 30px rgba(247, 198, 8, 0.25);
}
.top-counter {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  min-width: 1137px;
  max-width: 100%;
  padding: 40px;
  background: #222222;
  box-shadow: 0px 20px 250px rgba(0, 0, 0, 0.1);
  border-radius: 45px;
}
.counter-box {
  background: #2D2919;
  border: 2px dashed #6A5814;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 30px 0 15px;
  line-height: 43px;
  min-width: 200px;
}
.counter-box h2 {
  font-weight: bold;
  font-size: 70px;
  line-height: 43px;
  color: #F7C608;
  text-align: center;
  margin: 0;
}
.counter-box h2 small {
  margin: 0;
  font-weight: 500;
  font-size: 32px;
  line-height: normal;
  color: #FFFFFF;
}
.home-about {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 120px 0;
}
.home-about h2 {
  font-weight: bold;
  font-size: 50px;
  line-height: 55px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 20px;
}
.home-about p {
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #B5B5B5;
  text-align: center;
  margin: 0 0 40px;
}
.about-con-inner {
  display: inline-flex;
  align-items: flex-start;
  justify-content: space-between;
}
.about-con-box {
  width: 33.33%;
  padding: 20px;
}
.about-con-ic {
  display: inline-block;
  width: 100%;
  position: relative;
  vertical-align: top;
  text-align: center;
}
.about-con-ic span {
  background: #2D2919;
  border: 2px dashed #6A5814;
  box-sizing: border-box;
  border-radius: 26px;
  width: 86px;
  height: 86px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99;
}
.about-con-ic span img {
  height: 44px;
}
.about-con-box h3 {
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  margin: 28px 0 20px;
  color: #fff;
  text-align: center;
}
.about-con-box p {
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #B5B5B5;
  padding: 0 22px;
}
.about-con-ic::after {
  content: '';
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #6A5814;
  position: absolute;
  left: 50%;
  top: 50%;
}
.about-con-box:last-child .about-con-ic::after {
  content: none;
}
.home-degen-ape {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 120px 0;
  background: linear-gradient(180deg, #232323 0%, #1F1F1F 11.76%, #1B1B1B 42.79%, #1D1D1D 80.52%, #232323 100%);
}
.degen-ape-con-top, .degen-ape-con-bot {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.degen-ape-left {
  width: 50% !important;
}
.degen-ape-right {
  width: 50% !important;
}
.degen-ape-con-top {
  margin: 0 0 130px;
}
.degen-ape-con-top h2, .degen-ape-con-bot h2 {
  font-weight: bold;
  font-size: 50px;
  line-height: 55px;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 25px;
}
.degen-ape-con-top p, .degen-ape-con-bot p {
  font-weight: normal;
  font-size: 20px;
  line-height: 35px;
  color: #B5B5B5;
  margin: 0 0 35px;
}
.degen-ape-con-top .degen-ape-left {
  padding-right: 50px;
}
.degen-ape-con-top .degen-ape-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 40px;
}
.degen-ape-con-top .degen-ape-right .ape-imgright {
  margin: 0 0 0 38px;
  width: 327px;
  max-width: 100%;
}
.degen-ape-con-top .degen-ape-right .ape-imgright img {
  margin: 0 0 38px;
}
.degen-ape-con-top .degen-ape-right .ape-imgright img:last-child {
  margin: 0 0 0px;
}

.degen-ape-con-bot .degen-ape-right {
  padding-left: 50px;
}
.degen-ape-con-bot .degen-ape-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 0 0;
}
.degen-ape-con-bot .degen-ape-left .ape-imgright {
  margin: 0 0 0 38px;
}
.degen-ape-con-bot .degen-ape-left .ape-imgleft {
  margin: 38px 0 0 0;
}
.degen-ape-con-bot .degen-ape-left .ape-imgright img {
  margin: 0 0 38px;
}
.home-roadmap {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 120px 0;
}
.home-roadmap h2 {
  font-weight: bold;
  font-size: 50px;
  line-height: 88px;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 10px;
  text-align: center;
}
.home-roadmap p {
  font-weight: normal;
  font-size: 20px;
  line-height: 35px;
  color: #B5B5B5;
  margin: 0 0 80px;
  text-align: center;
  padding: 0 80px;
}


/* ================ The Timeline ================ */
.timeline {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0;
  list-style-type: none;
}
.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: ' ';
  display: block;
  width: 4.5px;
  height: 100%;
  margin-left: -3px;
  background: rgb(255,255,255, 0.3);
  z-index: 0;
}
.timeline li {
  padding: 0;
}
.timeline li:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.direction-l {
  position: relative;
  width: 50%;
  float: left;
  text-align: right;
}
.direction-r {
  position: relative;
  width: 50%;
  float: right;
}
.flag-wrapper {
  position: relative;
  display: inline-block; 
  text-align: center;
  width: 100%;
}
.time-wrapper {
  display: inline; 
  line-height: 1em;
  font-size: 0.66666em;
  color: rgb(250,80,80);
  vertical-align: middle;
}
.direction-l .time-wrapper {
  float: left;
}
.direction-r .time-wrapper {
  float: right;
}
.time {
  display: inline-block;
  padding: 4px 6px;
  background: rgb(248,248,248);
}
.desc {
  margin: 15px 40px 0 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #FFFFFF;
}
.direction-r .desc {
  margin: 15px 0 0 40px;
}
.flag-wrapper h4 {
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  color: #FFFFFF;
  width: 100%;
  position: relative;
  padding-bottom:20px;
  text-align: left;
}
.flag-wrapper h4::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 6px;
  background: url('image/time-b-right.svg');
  background-repeat: no-repeat;
  background-size:100% auto;
  background-position: left center;
  height: 6px;
}
.flag-wrapper h4::before {
  content: '';
  position: absolute;
  left: -20px;
  bottom: -15px;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  border: 1px dashed #F7C608;
  background: url('image/time-dot.svg');
  background-repeat: no-repeat;
  background-size: 39px;
  background-position: center;
  z-index: 9;
}
.direction-r .flag-wrapper h4 {
  padding-left: 40px;
  margin: 0;
}
.direction-l .flag-wrapper h4 {
  padding-right: 40px;
  text-align: right;
  margin: 0;
}
.direction-l .flag-wrapper h4::before {
  left: auto;
  right: -18px;
  bottom: -15px;
}
.direction-l .flag-wrapper h4::after {
  content: '';
  background: url('image/time-b-left.svg');
  background-repeat: no-repeat;
  background-size:auto 6px;
  background-position: right center;
  height: 6px;

}
/* ================ Timeline Media Queries ================ */
@media screen and (max-width: 660px) {
.timeline {
  width: 100%;
  padding: 4em 0 1em 0;
}
.timeline li {
  padding: 2em 0;
}
.direction-l,
.direction-r {
  float: none;
  width: 100%;
  text-align: center;
}
.flag-wrapper {
  text-align: center;
}
.time-wrapper {
  display: block;
  position: relative;
  margin: 4px 0 0 0;
  z-index: 14;
}
.direction-l .time-wrapper {
  float: none;
}
.direction-r .time-wrapper {
  float: none;
}
.desc {
  position: relative;
  margin: 1em 0 0 0;
  padding: 1em;
  background: rgb(245,245,245);
  -webkit-box-shadow: 0 0 1px rgba(0,0,0,0.20);
  -moz-box-shadow: 0 0 1px rgba(0,0,0,0.20);
  box-shadow: 0 0 1px rgba(0,0,0,0.20);
  z-index: 15;
}
.direction-l .desc,
.direction-r .desc {
  position: relative;
  margin: 1em 1em 0 1em;
  padding: 1em;
  z-index: 15;
}
}
@media screen and (min-width: 400px ?? max-width: 660px) {
.direction-l .desc,
.direction-r .desc {
  margin: 1em 4em 0 4em;
}
}
.slick-slider {
  margin: auto;
  width: 100%;
  padding: 0;
}
.slick-slider h3 {
  background: #FFF;
  color: #000;
  font-size: 136px;
  line-height: 200px;
  margin: 10px;
  padding: 2%;
  position: relative;
  text-align: center;
}
.slick-slider .slick-arrow {
  display: none !important;
}

.slick-slider .slick-dots li button {
  background: none;
  border-radius: 50%;
  border: 2px solid #FFF;
}
.slick-slider .slick-dots li button::before {
  display: none;
}
.slick-slider .slick-dots li.slick-active button {
  background: #F7C608;
  border: 2px solid #FFF;
  border-radius: 50%;
}
.actions {
  margin-bottom: auto;
}
.actions .button {
  margin: 10px;
  width: 100%;
}
@media (min-width: 769px) {
  .actions .button {
    width: auto;
  }
}

/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 60px 0 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}
.button {
  background: #fff;
  border: 0 none;
  border-radius: 30px;
  color: #000;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 40px;
  text-transform: uppercase;
}
.our-team-slider {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}
.team-slider {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.home-our-team {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 327px 0 310px;
  background: url('image/team-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: -327px 0 -190px;
}
.home-our-team h2 {
  font-weight: bold;
  font-size: 50px;
  line-height: 88px;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 50px;
  text-align: center;
}
.our-team-slider {
  padding: 120px 0 0;
}
.team-box {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 10px;
    text-align: center;
}
.team-box .team-img {
  position: relative;
  margin: 40px 0 0;
  padding: 15px;
}
.team-box .team-img .team-user {
display: inline-block;
border-radius: 50%;
border: 15px solid transparent;
position: relative;
padding: 10px;
}
.team-box .team-img .team-user img {
  border-radius: 50%;
  position: relative;
}
.team-box:hover .team-img .team-user {
  border-radius: 50%;
  border: 15px solid #E0E4E5;
  cursor: pointer;
}
.team-box:hover .team-img .team-user::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  background: linear-gradient(90deg, #442582 -24.98%, #442582 125.57%);
}
.team-box:hover .team-img .team-user::after {
  content: '';
  width: 92%;
  height: 92%;
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
  border-radius: 50%;
  border: 9px solid #FF736F;
  /*background: linear-gradient(90deg, #442582 -24.98%, #442582 125.57%);*/
}
.team-box:hover .team-img::after {
  content: '';
  width: 100%;
  height: 92%;
  position: absolute;
  left: 0;
  top: -20px;
  background: url('image/team-hover.svg');
  background-repeat: no-repeat;
  background-position: center top;
}
.team-box-con {
  background: #292929;
  border-radius: 30px;
  padding: 150px 10px 25px;
  margin: -150px 0 0;
}
.team-box-con h5 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  margin: 0 0 10px;
}
.team-box-con p {
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  margin: 0;
  /*letter-spacing: -1px;*/
}
.team-box:hover .team-box-con {
  background: #F7C608;
  border-radius:10px;
  border: 1px solid #fff;
}
.team-box:hover .team-box-con h5 {
  color: #000;
}
.team-box:hover .team-box-con p {
  color: #000;
}
.home-faq {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 120px 0;
}
.faq-inner {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.faq-inner h2 {
  font-weight: bold;
  font-size: 50px;
  line-height: 55px;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 70px;
  text-align: center;
}
.home-faq .accordion {
  padding:0 40px;
  background: #222222;
  box-shadow: 0px 30.949px 80.4674px rgba(0, 0, 0, 0.05);
  border-radius: 24.7592px;
}
.home-faq .accordion .card {
  background: none;
  border: none !important;
  border-radius: 0;
  border-bottom: 1px solid #fff !important;
}
.home-faq .accordion .card:last-child {
  border-bottom: none !important;
}
.home-faq .accordion .card .card-header {
  padding: 0;
  border: none !important;
  background: none;
}
.home-faq .accordion .card .card-header h2 {
  text-align: left;
}
.home-faq .accordion .card .card-header h2 .btn-link {
  text-align: left;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-transform: capitalize;
  color: #fff;
  text-decoration: none;
  padding: 40px 0 40px 40px;
  position: relative;
}
.home-faq .accordion .card .card-body {
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  text-transform: capitalize;
  color: #fff;
  padding: 0 40px;
  margin: 0 0 40px;
}
.home-faq .accordion .card .card-header h2 .btn-link::before {
  content: '';
  background: url('image/faq-arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 45px;
  width: 24px;
  transform: rotate(90deg);
  height: 24px;
}
.home-faq .accordion .card .card-header h2 .btn-link.collapsed::before {
  content: '';
  background: url('image/faq-arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 45px;
  width: 24px;
  height: 24px;
  transform: rotate(0deg);
}
.home-client {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 0 0 120px;
}
.client-logos {
  display: flex;
  align-items: center;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
}
.client-logos .logos {
  padding: 0 15px;
}
.footer {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 100px 0;
  background: #222222
}
.footer h2 {
  font-weight: bold;
  font-size: 40px;
  line-height: 55px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin: 0 0 30px;
}
.footer p {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin: 0 ;
}
.footer h5 {
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  color: #FFFFFF;
  margin: 0 0 30px;
}
.footer ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.footer ul li {
  background: rgba(255, 255, 255, 0.1);
  width: 72px;
  height: 72px;
  border-radius: 50%;
  padding: 10px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
}
.footer ul li:hover {
  box-shadow: 0 15px 30px rgba(255, 255, 255, 0.1);
}
.footer ul li:first-child {
  margin-left: 0;
}
.footer ul li:last-child {
  margin-right: 0;
}
.footer .row {
  justify-content: space-between;
}
.footer ul li a img {
  max-height: 30px;
  opacity: .5;
}
.footer ul li:hover a img {
  max-height: 30px;
  opacity: 1;
}
.home-degen-ape .button1 {
  min-width: 198px;
  min-height: 55px;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
}

@media only screen and (max-width:3440px){

}
@media only screen and (max-width:2580px){

}
@media only screen and (max-width:1920px){

}
@media only screen and (max-width:1440px){

}
@media only screen and (max-width:1366px){
.header-main .container {
    width: 100%;
    max-width: 100%;
}
.header-main .navbar-brand {
    margin-right: 0;
}
.header-main .navbar-brand img {
    height: 60px;
}
.get-in-touch {
  padding: 10px 20px;
}
.container {
    width: 1200px;
    max-width: 100%;
}
.flag-wrapper h4 {
  font-size: 24px;
  line-height: 24px;
}
.team-box-con p {
  min-height: 40px;
}
.home-faq .accordion .card .card-header h2 .btn-link {
  font-size: 24px;
  line-height: 30px;
}
.home-faq .accordion .card .card-body {
  font-size: 20px;
  line-height: 28px;
}
.footer ul li {
  width: 60px;
  height: 60px;
}
h2 {
  font-size: 36px !important;
  line-height: 45px !important;
}
h1 {
  font-size: 55px !important;
  line-height: 65px !important;
}
.counter-box p {
  font-size: 22px;
}
}
@media only screen and (max-width:1280px){
.top-counter {
  min-width: 90%;
}
.counter-box {
  min-width: 20%;
}
.home-top .row, .home-top {
    min-height: 800px;
}
.row {
  margin: 0;
}
.home-about, .home-degen-ape, .home-roadmap, .home-faq, .footer, .home-our-team {
  padding: 60px 0;
}
.home-our-team {
  margin: 0;
}
.home-client {
  padding: 0 0 60px;
}
.degen-ape-con-top {
  margin: 0 0 60px;
}
.our-team-slider {
    padding: 60px 0 0;
}
}
@media only screen and (max-width:1024px){
.header-main .navbar-nav .nav-item {
    margin-left: 20px;
}
.h-ic {
  width: 45px;
  height: 45px;
}
.get-in-touch {
    padding: 6px 15px;
}
.home-faq .accordion .card .card-header h2 .btn-link::before {
  width: 18px;
  height: 18px;
}
.home-faq .accordion .card .card-header h2 .btn-link.collapsed::before {
  width: 18px;
  height: 18px;
}
.desc {
  font-size: 16px;
  line-height: 28px;
}
h2 {
  font-size: 28px !important;
  line-height: 36px !important;
}
h1 {
  font-size: 42px !important;
  line-height: 52px !important;
}
.counter-box p {
  font-size: 18px;
}
.top-counter {
  border-radius: 25px;
}
.counter-box {
  border-radius: 15px;
}
.about-con-ic span {
  border-radius: 15px;
}
.footer ul li {
  width: 50px;
  height: 50px;
  padding: 12px;
}
.about-con-box p {
  padding: 0;
}
}
@media only screen and (max-width:991px){
.degen-ape-con-top .degen-ape-right .ape-imgright {
    margin: 0 0 0 38px;
    width: 70%;
}
.navbar-toggler img {
  width: 35px;
}
.header-main .navbar-brand img {
    height: 45px;
}
.header-main {
  padding: 15px 0 0;
}
.navbar-collapse {
  background: rgba(0, 0, 0, 0.9);
  padding: 15px 0;
}
.form-inline {
  padding: 15px 15px 0;
}
.get-in-touch {
  font-size: 0;
  background: #41413F;
  min-width: 45px !important;
  min-height: 45px !important;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.get-in-touch img {
    margin-left: 0px;
    height: 22px;
}
.home-about p {
  font-size: 16px;
  line-height: 28px;
}
.home-about p br {
  display: none;
}
.degen-ape-con-top p, .degen-ape-con-bot p {
  font-size: 16px;
  line-height: 28px;  
}
.direction-l .desc, .direction-r .desc {
  color: #B5B5B5;
  margin: 10px 0 0;
}
.header-main .navbar-nav .nav-item:first-child {
    margin-left: 20px;
}
}
@media only screen and (max-width:767px){
h2 {
  font-size: 24px !important;
  line-height: 30px !important;
}
h1 {
  font-size: 34px !important;
  line-height: 40px !important;
}
.counter-box p {
  font-size: 16px;
}
.home-top-con h1 br {
  display: none;
}
.c-dott {
  display: none;
}
.top-counter {
  flex-wrap: wrap;
  padding: 20px 20px 0;
}
.counter-box {
  width: 48%;
  margin: 0 0 20px;
}
.about-con-inner {
  flex-wrap: wrap;
}
.about-con-box {
  width: 100%;
}
.about-con-ic::after {
  content: none;
}
.degen-ape-con-top, .degen-ape-con-bot {
  flex-wrap: wrap;
}
.degen-ape-con-top .degen-ape-left {
    padding-right: 0;
    width: 100% !important;
    order: 2;
}
.degen-ape-con-top .degen-ape-right {
    padding-left: 0;
    width: 100% !important;
    margin: 30px 0 0;
    order: 1;
}
.degen-ape-con-bot .degen-ape-left {
    padding-left: 0;
    width: 100% !important;
}
.degen-ape-con-bot .degen-ape-right {
    padding-left: 0;
    width: 100% !important;
    margin: 30px 0 0;
}
.home-roadmap p {
  padding: 0;
  font-size: 16px;
  line-height: 28px;
}
.timeline::before {
  content: none;
}
.direction-l .flag-wrapper h4::before { 
  content: none;
}
.flag-wrapper h4::before {
  content: none;
}
.direction-l .flag-wrapper h4 {
    padding-right: 40px;
    text-align: right;
}
.direction-l .flag-wrapper h4::after {
    background: linear-gradient(90deg, #F7C608 0%, rgba(255, 255, 255, 0) 100%);
}
.direction-r .flag-wrapper h4 {
  padding-left: 0;
  font-size: 18px;
  line-height: 20px;
}
.direction-l .flag-wrapper h4 {
  padding-right: 0;
  text-align: left;
  font-size: 18px;
  line-height: 20px;
}
.timeline {
  padding: 0;
}
.direction-l .desc, .direction-r .desc {
    position: relative;
    margin: 10px 0 20px;
    padding: 0;
    z-index: 15;
    background: none;
    text-align: left;
}
.timeline li {
    padding: 1em 0;
}
.home-about, .home-degen-ape, .home-roadmap, .home-faq, .footer, .home-our-team {
  padding: 30px 0;
}
.home-our-team {
  margin: 0;
}
.home-client {
  padding: 0 0 30px;
}
.degen-ape-con-top {
  margin: 0 0 30px;
}
.our-team-slider {
    padding: 30px 0 0;
}
.button1 {
  min-width: 210px;
  font-size: 18px;
  line-height: 30px;
}
.home-faq .accordion .card .card-header h2 .btn-link {
  font-size: 18px;
  line-height: 28px;
  padding: 20px 0 20px 30px;
  white-space: normal;
}
.home-faq .accordion {
  padding: 0 20px;
}
.home-faq .accordion .card .card-header h2 .btn-link::before {
    width: 18px;
    height: 18px;
    top: 25px;
}
.home-faq .accordion .card .card-header h2 .btn-link.collapsed::before {
    width: 18px;
    height: 18px;
    top: 25px;
}
.home-faq .accordion .card .card-body {
  font-size: 16px;
  line-height: 24px;
  padding: 0 30px;
  margin: 0 0 30px;
}
.client-logos .logos {
    padding: 0 10px;
    width: 48%;
    margin: 0 0 15px;
}
.client-logos {
  flex-wrap: wrap;
}
.client-logos .logos img {
  max-height: 30px;
}
.footer p {
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 30px;
}
.footer h2 {
  margin: 0 0 10px;
}
.footer h5 {
  font-size: 24px;
  line-height: 28px;
}
.footer ul li {
  margin: 0 7px;
}
.footer ul li:first-child {
    margin-left: 0;
}
.footer ul li:last-child {
    margin-right: 0;
}
.slick-dots {
  margin: 30px 0 0;
}
.faq-inner h2, .home-our-team h2 {
  margin: 0 0 30px;
}
.about-con-box h3 {
  font-size: 24px;
  line-height: 30px;
}

}
@media only screen and (max-width:580px){

}
@media only screen and (max-width:390px){

}